@import "colors";

//Defaults
.ng-select ::ng-deep .ng-select-container {
  background-color: $background-color;
  border-color: $menu-link-color-hover;
  border-width: 2px  !important;
  color: white;
}

.ng-select ::ng-deep .ng-input input {
  color: white;
}

.ng-select ::ng-deep .ng-dropdown-panel {
  border-color: $menu-link-color-hover !important;
  border-width: 2px !important;
  background-color: $menu-link-color-hover !important;
}

.ng-select ::ng-deep .ng-dropdown-panel .ng-option {
  background-color: $background-color;
  color: white;
}

.ng-select ::ng-deep .ng-dropdown-panel .ng-option.ng-option-marked {
  background-color: $menu-link-color-hover !important;
}

//Dark mode
.ng-select.dark ::ng-deep .ng-select-container {
  background-color: $background-color;
  border-color: $menu-link-color-hover;
  color: white;
}

.ng-select.dark ::ng-deep .ng-input input {
  color: white;
}

.ng-select.dark ::ng-deep .ng-dropdown-panel {
  border-color: $menu-link-color-hover !important;
  background-color: $menu-link-color-hover !important;
}

.ng-select.dark ::ng-deep .ng-dropdown-panel .ng-option {
  background-color: $background-color;
  color: white;
}

.ng-select.dark ::ng-deep .ng-dropdown-panel .ng-option.ng-option-marked {
  background-color: $menu-link-color-hover !important;
}

//Light Mode
.ng-select.light ::ng-deep .ng-select-container {
  background-color: white;
  border-color: $menu-link-color-hover;
  color: black;
}

.ng-select.light ::ng-deep .ng-input input {
  color: black;
}

.ng-select.light ::ng-deep .ng-dropdown-panel {
  border-color: $menu-link-color-hover !important;
  background-color: $menu-link-color-hover !important;
}

.ng-select.light ::ng-deep .ng-dropdown-panel .ng-option {
  background-color: white;
  color: black;
}

.ng-select.light ::ng-deep .ng-dropdown-panel .ng-option.ng-option-marked {
  background-color: $menu-link-color-hover !important;
  color: white;
}
