$menu-color: #303030;
$background-color: #303030;
$menu-link-color: #e9ecef;
$menu-link-color-hover: #002c71;
$card-border-color: #757780;
$card-background-color: #424242;
$selected-card-background-color: #002c7133;
$selected-card-border-color: #002c71;
$date-picker-icon-color: #757780;
$button-border-color: #3f80ea;
$delete-color-button: rgb(208,47,39);
$delete-color-container: rgba(208,47,39, 0.1);
