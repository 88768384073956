/* You can add global styles to this file, and also import other style files */

@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/bootstrap.scss';
@import '~@swimlane/ngx-datatable/themes/material.scss';
@import '~@swimlane/ngx-datatable/themes/dark.scss';
@import '~@swimlane/ngx-datatable/assets/icons.css';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

@import "styles/ng-select";

@import "styles/colors";

body {
  background-color: $background-color !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance:textfield; /* Firefox */
}

.card {
  background-color: $card-background-color;
  border: 0 solid transparent;
  border-radius: 8px;
}

.mat-card{
  background: $card-background-color;
  border-radius: 8px;
}

.ngx-datatable {
  width: 100%;
}

.page-title {
  margin-bottom: 1rem;
  color: white;
  font-weight: 500;
}

.card-title {
  color: white;
}

h1,
h2,
h3,
h4,
h5,
label{
  color: white;
}

.mat-cell, .mat-header-cell {
  background-color: $card-background-color;
  color: white;
}

.mat-card{
  //border: 1px solid $card-border-color;
  background: $background-color;
}

.mat-form-field{
  width: 100%;
}

.mat-card-title{
  color: white !important;
}

.mat-card-subtitle{
  color: white !important;
}

.mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: $card-border-color;
  background-color: $card-background-color;
  border: 1px solid $card-border-color !important;
}

::ng-deep .mat-input-element {
  caret-color: white !important;
}
/* Font color */
::ng-deep input.mat-input-element {
  color: white !important;
}
::ng-deep .mat-form-field-empty.mat-form-field-label {
  color: white !important;
}
::ng-deep .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  color: white !important;
}

::ng-deep .mat-focused .mat-form-field-required-marker {
  color: white !important;
}

::ng-deep .mat-select-panel .mat-option {
  background: $background-color !important;
  color: white !important;
}

::ng-deep .mat-select-panel .mat-selected {
  background: $selected-card-border-color !important;
  color: white !important;
}

::ng-deep .mat-select-value {
  color: white !important;
}

::ng-deep .mat-select-arrow {
  color: white !important;
}

/* Border */
::ng-deep .mat-form-field-appearance-outline .mat-form-field-outline {
  color: $card-border-color !important;
}

::ng-deep .mat-form-field-appearance-fill .mat-form-field-underline::before{
  background: $selected-card-border-color !important;
}

::ng-deep .mat-form-field-appearance-fill .mat-form-field-ripple {
  background: $selected-card-border-color !important;
}

// Date picker
::ng-deep .mat-datepicker-toggle {
  color: $date-picker-icon-color !important;
}
::ng-deep .mat-focused .mat-form-field-label {
  /*change color of label*/
  color: $date-picker-icon-color !important;
}

::ng-deep.mat-form-field-underline {
  /*change color of underline*/
  background-color: $date-picker-icon-color !important;
}

::ng-deep.mat-form-field-ripple {
  /*change color of underline when focused*/
  background-color: $date-picker-icon-color !important;;
}

::ng-deep .mat-calendar {
  background-color: $card-background-color !important;
  color: white !important;
}

::ng-deep .mat-calendar-body-cell-content, .mat-date-range-input-separator {
  color: white !important;
}

::ng-deep .mat-datepicker-toggle {
  color: white !important;
}

::ng-deep .mat-datepicker-content .mat-calendar-next-button {
  color: white !important;
}

::ng-deep .mat-datepicker-content .mat-calendar-previous-button{
  color: white !important;
}

::ng-deep .mat-calendar-body-label {
  color: white !important;
}

::ng-deep .mat-calendar-table-header{
  color: white !important;
}

::ng-deep .mat-calendar-body-selected{
  background: $selected-card-border-color !important;
}

@media (hover: hover){
  ::ng-deep .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
    background: $selected-card-background-color !important;
  }
}

::ng-deep .mat-calendar-body-active.mat-calendar-body-cell-content:not(.mat-calendar-body-selected){
  background: $selected-card-background-color !important;
}

::ng-deep .mat-calendar-arrow{
  fill: white !important;
}

.example-chip-list {
  width: 100%;
  background-color: #fafafa;
  border-radius: 4px;
}

::ng-deep .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: #293042;
}

::ng-deep .mat-focused .mat-form-field-label {
  color: #293042 !important;
}

::ng-deep.mat-form-field-ripple {
  background-color: #293042 !important;;
}


::ng-deep input.mat-chip-input {
  color: black;
}

.modal-custom-window .modal-content {
  background-color: $card-background-color !important;
  border-radius: 8px !important;
}
